import React from 'react';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import './Chart.css';

const Chart = ({ data, chartKey }) => {
    return (
        <div>
            <h2>{chartKey}</h2>
            <BarChart className="chart m-btm-med" width={400} height={200} data={data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                <YAxis />
                <Tooltip />
                {/* <Legend width={100} wrapperStyle={{ top: 160, right: 20, backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '40px' }} /> */}
                <Bar dataKey={chartKey} fill="#8884d8" />
            </BarChart>
        </div>
    )
};

export default Chart;
