import axios from 'axios';

const baseUrl = 'https://bbi.brndn.me/v1';
// const baseUrl = 'http://localhost:1337/v1'

async function yearlyCycling(user) {
    if (!user) {
        return;
    }
    const url = `/reports/cycling/yearly/${user}`;
    console.log(baseUrl + url);
    return await get(baseUrl + url);
}

async function get(url) {
    // return await axios.get(url, { withCredentials: true })
    return await axios.get(url)
        .then(response => {
            return response;
        });
}

const Services = {
    yearlyCycling,
};

export default Services;
