import React, { Component } from 'react';
import './App.css';

import Home from './Home.js';

class App extends Component {
    render() {
        return (
            <div className="app">
                {/* <header className="app-header">
                    <h1 className="no-margin"></h1>
                </header> */}
                <Home />
            </div>
        );
    }
}

export default App;
