import React, { Component } from 'react';
import './Home.css';

import Services from './Services.js';
import Chart from './components/Chart.js';

const search = window.location.search;
const params = new URLSearchParams(search);
const user = params.get('u') || 'brandon';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            yearlyCycling: [],
        };
    };

    async componentDidMount() {
        const yearlyCycling = await Services.yearlyCycling(user);
        console.log(yearlyCycling.data.data);
        this.setState({
            yearlyCycling: yearlyCycling.data.data,
        });
    };

    render() {
        return (
            <div className="container">
                <h1>Yearly Cycling</h1>
                <div className="flex">
                    <Chart data={this.state.yearlyCycling} chartKey="miles" />
                    <Chart data={this.state.yearlyCycling} chartKey="rideDays" />
                    <Chart data={this.state.yearlyCycling} chartKey="hours" />
                    <Chart data={this.state.yearlyCycling} chartKey="climbing" />
                    <Chart data={this.state.yearlyCycling} chartKey="calories" />
                </div>
            </div>
        );
    }

}

export default Home;
